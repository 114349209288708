import { Alert, Button, ConfigProvider, Form, Input } from "antd";
import "../styles/login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const handleSubmit = async (e) => {
    setLoading(true);
    setMessage("");
    if (!email || !password) {
      setMessage({ error: true, msg: "All fields are required" });
      setLoading(false);
    } else {
      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        form.resetFields();
        setLoading(false);
        setMessage("");
        Navigate("/dashboard");
      } catch (err) {
        setMessage({ error: true, msg: err.message });
        setLoading(false);
      }
    }
  };
  return (
    <div className="login-container">
      <h1>Admin Login</h1>
      <div className="login-form">
        <ConfigProvider
          button={{
            token: {
              controlHeight: 200,
            },
          }}
          theme={{
            token: {
              fontFamily: "Poppins",
              controlHeight: 50,
              colorPrimary: "#1e2e3e",
            },
          }}
        >
          <Form
            className="loginForm"
            onSubmit={(e) => e.preventDefault()}
            layout="vertical"
            size={"small"}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 50 }}
            onFinish={handleSubmit}
          >
            {message?.msg && (
              <Alert
                message=""
                description={message?.msg}
                type="error"
                showIcon
              />
            )}
            <Form.Item
              label="Email Address"
              name="email"
              style={{ marginBottom: "10px" }}
              rules={[
                {
                  required: false,
                  message: "Please enter a valid product name",
                },
              ]}
            >
              <Input
                style={{ borderRadius: "10px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              style={{ marginBottom: "20px" }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.Password
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                style={{borderRadius: "10px" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            {loading ? (
              <Button
                style={{
                  fontWeight: "800",
                  backgroundColor: "#1e2e3e",
                  paddingRight: "80px",
                  paddingLeft: "80px",
                }}
                type="primary"
                htmlType="submit"
                loading
                disabled
              >
                Login
              </Button>
            ) : (
              <Button
                style={{
                  fontWeight: "800",
                  backgroundColor: "#1e2e3e",
                  paddingRight: "80px",
                  paddingLeft: "80px",
                }}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            )}
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Login;
