import P1 from "../styles/P1.jpg";
import P2 from "../styles/P2.jpg";
import P3 from "../styles/P3.jpg";
import P4 from "../styles/P4.jpg";
import P5 from "../styles/P5.jpg";
import P6 from "../styles/P6.jpg";
import P7 from "../styles/P7.png";
import P8 from "../styles/P8.png";
import P9 from "../styles/P9.png";
// import { motion } from "framer-motion";
import { RiMenu5Fill } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { useState } from "react";
import "../styles/home.css";
import logo from "../styles/logo.svg";
import whatsaap from "../styles/whatsaap.svg";
import arrow2 from "../styles/arrow1.svg";
import { SiUpwork } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp} from "firebase/firestore";
import {
  FaInstagram,
  FaTwitter,
  FaTelegramPlane,
  FaLinkedin,
} from "react-icons/fa";
import { ConfigProvider, Form, Input, Button,message } from "antd";
const { TextArea } = Input;

const Portfolio = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [clicked, setClicked] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [_message, setMessage] = useState("");

  const handleClick = () => {
    setClicked(!clicked);
  };
  const handleSubmit = async () => {
    try {
      if (
        name === "" ||
        phone === "" ||
        email === "" ||
        subject === "" ||
        _message === ""
      ) {
        messageApi.open({
          type: "error",
          content: "Please all fields are required",
        });
      } else {
        setLoading(true);
        await addDoc(collection(db, "responses"), {
          name: name,
          phone: phone,
          email: email,
          subject: subject,
          message: _message,
          added: serverTimestamp(),
        });
        setLoading(false);
        form.resetFields();
        messageApi.open({
          type: "success",
          content: "Message sent succesfully",
        }).then(() => {
          window.location.reload()
        });
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };
  return (
    <>
    {contextHolder}
      <nav className="navbar">
        <div className={clicked ? "container active" : "container"}>
          <div onClick={() => navigate("/")} className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="nav-elements">
            <ul>
              <li onClickCapture={handleClick}>
                <HashLink to="/#services">Services</HashLink>
              </li>
              <li onClickCapture={handleClick}>
                <HashLink to="/portfolio">Portfolio</HashLink>
                {/* <Link to="/portfolio">Portfolio</Link> */}
              </li>
              <li onClickCapture={handleClick}>
                <HashLink to="/#contact">Contact Me</HashLink>
              </li>
            </ul>
          </div>
          <a
            href="https://wa.me/2349127057829?text=Hello%2C%20i%20have%20a%20question%20about%20your%20service"
            target="_blank"
            rel="noopener noreferrer"
            className="a"
          >
            <div className="whatsaap">
              <img src={whatsaap} alt="" />
              <h1>Chat with me</h1>
            </div>
          </a>
          <div onClickCapture={handleClick} className="menu">
            {clicked ? <IoIosClose /> : <RiMenu5Fill />}
          </div>
        </div>
      </nav>
      <section className="portfolio">
        <div className="fab-container">
          <a
            href="https://wa.me/2349127057829?text=Hello%2C%20i%20have%20a%20question%20about%20your%20service"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaWhatsapp />
            </span>
          </a>
        </div>
        <h1>
          Welcome to my <span>PORTFOLIO</span>
        </h1>
        <div className="portfolio-container">
          <div className="portfolio-wrap">
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P1} alt="" />
              </div>
              <h4>Department of Corrections</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P2} alt="" />
              </div>
              <h4>Fearless Fund Application</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P3} alt="" />
              </div>
              <h4>Department of Corrections</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P4} alt="" />
              </div>
              <h4>Department of Corrections</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P5} alt="" />
              </div>
              <h4>Columbia bank Foundation</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P6} alt="" />
              </div>
              <h4>CPU Automaintenance</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P7} alt="" />
              </div>
              <h4>Department of Corrections</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P8} alt="" />
              </div>
              <h4>Vantage Sales Inc.</h4>
            </div>
            <div className="port-cont">
              <div className="p-imgC">
                <img src={P9} alt="" />
              </div>
              <h4>II Chances Inc.</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-porfolio">
        <div className="contact-form2">
          <div className="cf-line1"></div>
          <h4>
            Lets make magic together Start by saying <span>HI</span>
          </h4>
          <div className="cf-line2">
            <div className="contact-btn">
              <img src={arrow2} alt="" />
            </div>
          </div>
          <div className="socials-details">
            <div className="logo-name">
              <img src={logo} alt="" />
              <h1>igital Nerd</h1>
            </div>
            <div className="social-icons">
              <span>
                <FaInstagram />
              </span>
              <span>
                <a
                  href="https://twitter.com/Hon_Joshuaa?t=R4nOBq9Ir3My84hh1NC2AQ&s=09"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              </span>
              <span>
                <FaTelegramPlane />
              </span>
              <span>
                <a
                  href="https://www.upwork.com/freelancers/~01d69e10b3d4fc03a6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SiUpwork />
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/in/honour-afangideh-230872246/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="contact-form">
        <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#1e2e3e",
                colorTextPlaceholder: "#fff",
              },
              components: {
                Input: {
                  activeBorderColor: "yellow",
                },
              },
            }}
          >
            <Form
              form={form}
              className="form"
              onFinish={handleSubmit}
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Item
                label=""
                name="name"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="NAME"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="phone"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="PHONE NUMBER"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="email"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="EMAIL"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid subject",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="SUBJECT"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Message is empty",
                  },
                ]}
              >
                <TextArea
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="MESSAGE"
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={_message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <hr />
              </Form.Item>
              {loading ? (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "100px",
                    paddingLeft: "100px",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading
                  disabled
                >
                  Send Message
                </Button>
              ) : (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "100px",
                    paddingLeft: "100px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              )}
            </Form>
          </ConfigProvider>
        </div>
      </section>
      <section className="contact2">
        <h1>
          Lets make magic together Start by saying <span>HI</span>
        </h1>
        <div className="contact-form">
        <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#1e2e3e",
                colorTextPlaceholder: "#fff",
              },
              components: {
                Input: {
                  activeBorderColor: "yellow",
                },
              },
            }}
          >
            <Form
              form={form}
              className="form"
              onFinish={handleSubmit}
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Item
                label=""
                name="name"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="NAME"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="phone"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="PHONE NUMBER"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="email"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="EMAIL"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid subject",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="SUBJECT"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Message is empty",
                  },
                ]}
              >
                <TextArea
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="MESSAGE"
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={_message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <hr />
              </Form.Item>
              {loading ? (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "50px",
                    paddingLeft: "50px",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading
                  disabled
                >
                  Send Message
                </Button>
              ) : (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "50px",
                    paddingLeft: "50px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              )}
            </Form>
          </ConfigProvider>
        </div>
        <div className="contact-form2">
          <div className="logo-name2">
            <img src={logo} alt="" />
            <h4>igital Nerd</h4>
          </div>
          <div className="social-icons">
            <span>
              <FaInstagram />
            </span>
            <span>
              <a
                href="https://twitter.com/Hon_Joshuaa?t=R4nOBq9Ir3My84hh1NC2AQ&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </span>
            <span>
              <FaTelegramPlane />
            </span>
            <span>
              <a
                href="https://www.upwork.com/freelancers/~01d69e10b3d4fc03a6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiUpwork />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/in/honour-afangideh-230872246/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </span>
          </div>
        </div>
      </section>
      <footer>
        <hr />
        <div className="footer">
          <h2>2023 all rights reserved</h2>
          <div className="footer-2">
            <h3>Designed by tems</h3>
            <span className="one">
            <FaInstagram/>
            </span>
            <span className="two">
            <FaTwitter/>
            </span>
          </div>
          <div className="footer-socials">
          <span className="one">
            <FaInstagram/>
            </span>
            <span className="two">
            <FaTwitter/>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Portfolio;
