import React from "react";
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp} from "firebase/firestore";
import { useState } from "react";
import "../styles/home.css";
import logo from "../styles/logo.svg";
import whatsaap from "../styles/whatsaap.svg";
import arrow from "../styles/arrow.svg";
import arrow2 from "../styles/arrow1.svg";
import avatar from "../styles/avatar.png"
import mentors from "../styles/mentor.png"
import { AiOutlineBulb } from "react-icons/ai";
import { CiMoneyBill } from "react-icons/ci";
import { TbBrandAngular } from "react-icons/tb";
import { SiUpwork } from "react-icons/si";
import { RiMenu5Fill } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa6";
import R1 from "../styles/R1.jpeg";
import R2 from "../styles/R2.jpeg";
import R3 from "../styles/R3.jpeg";
import R4 from "../styles/R4.jpeg";
import R5 from "../styles/R5.mp4";
import R6 from "../styles/R6.mp4";
import { motion } from "framer-motion";
import {
  FaInstagram,
  FaTwitter,
  FaTelegramPlane,
  FaLinkedin,
} from "react-icons/fa";
import { ConfigProvider, Form, Input, Rate, message, Button } from "antd";
const { TextArea } = Input;

const Home = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [clicked, setClicked] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [_message, setMessage] = useState("");

  const handleClick = () => {
    setClicked(!clicked);
  };
  const handleSubmit = async () => {
    try {
      if (
        name === "" ||
        phone === "" ||
        email === "" ||
        subject === "" ||
        _message === ""
      ) {
        messageApi.open({
          type: "error",
          content: "Please all fields are required",
        });
      } else {
        setLoading(true);
        await addDoc(collection(db, "responses"), {
          name: name,
          phone: phone,
          email: email,
          subject: subject,
          message: _message,
          added: serverTimestamp(),
        });
        setLoading(false);
        form.resetFields();
        messageApi.open({
          type: "success",
          content: "Message sent succesfully",
        }).then(() => {
          window.location.reload()
        });
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };
  return (
    <div className="landing">
      {contextHolder}
      <nav className="navbar">
        <div className={clicked ? "container active" : "container"}>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="nav-elements">
            <ul>
              <li onClickCapture={handleClick}>
                <a href="#services">Services</a>
              </li>
              <li onClickCapture={handleClick}>
                <a href="/portfolio">Portfolio</a>
              </li>
              <li onClickCapture={handleClick}>
                <a href="#contact">Contact Me</a>
              </li>
            </ul>
          </div>
          <a
            href="https://wa.me/2349127057829?text=Hello%2C%20i%20have%20a%20question%20about%20your%20service"
            target="_blank"
            rel="noopener noreferrer"
            className="a"
          >
            <div className="whatsaap">
              <img src={whatsaap} alt="" />
              <h1>Chat with me</h1>
            </div>
          </a>
          <div onClickCapture={handleClick} className="menu">
            {clicked ? <IoIosClose /> : <RiMenu5Fill />}
          </div>
        </div>
      </nav>
      <section className="main">
        <div className="fab-container">
          <a
            href="https://wa.me/2349127057829?text=Hello%2C%20i%20have%20a%20question%20about%20your%20service"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaWhatsapp />
            </span>
          </a>
        </div>
        <div className="main-details">
          <div className="details-text">
            <h1>
              Hey There, I’m <span>Honour</span>
            </h1>
          </div>
          <div className="main-container">
            <div className="details-line">
              <hr />
            </div>
            <div className="img-container">
              <img src={avatar} alt="" />
            </div>
          </div>
          <div className="container-details">
            <p className="one">
              A freelance writer and expert in grant writing services
            </p>
            <div className="line"></div>
            <p className="one">
              A skilled writer with a clientele that cuts across Nigeria, USA
              and Canada
            </p>
          </div>
        </div>
        <div className="main-details2">
          <div className="details-text">
            <h1>
              Hey There, I’m <span>Honour</span>
            </h1>
          </div>
          <hr />
          <div className="container-details">
            <p className="one">
              A freelance writer and expert in grant writing services
            </p>
            <p className="two">
              A skilled writer with a clientele that cuts across Nigeria, USA
              and Canada
            </p>
          </div>
          <hr />
          <div className="img-container">
          <img src={avatar} alt="" />
          </div>
        </div>
      </section>
      <section className="services" id="services">
        <div className="sr-1-container">
          <div className="sr-1">
            <div className="icon">
              <span>
                <AiOutlineBulb />
              </span>
            </div>
            <div className="sr-details">
              <h1>training</h1>
              <p>
                A fully endorsed package for freelance training and digital
                skills empowerment.
              </p>
            </div>
          </div>
          <div className="heading">
            <h1>SERVICES</h1>
          </div>
        </div>
        <div className="sr-2">
          <div className="icon">
            <span>
              <CiMoneyBill />
            </span>
          </div>
          <div className="sr-details">
            <h1>GRANT WRITING</h1>
            <p>
              A skilled writer with a clientele that cuts across Nigeria, USA
              and Canada
            </p>
          </div>
        </div>
        <div className="sr-3">
          <div className="icon">
            <span>
              <TbBrandAngular />
            </span>
          </div>
          <div className="sr-details">
            <h2>BUSINESS NAME AND SLOGANS</h2>
            <p>
              Crafting Bespoke brand names that speak to your target audience.
            </p>
          </div>
        </div>
      </section>
      <section className="services-2">
        <h1 className="services-heading">SERVICES</h1>
        <div className="sr2-1">
          <div className="icon">
            <span>
              <AiOutlineBulb />
            </span>
          </div>
          <div className="sr-details">
            <h1>training</h1>
            <p>
              A fully endorsed package for freelance training and digital skills
              empowerment.
            </p>
          </div>
        </div>
        <div className="sr2-2">
          <div className="icon">
            <span>
              <CiMoneyBill />
            </span>
          </div>
          <div className="sr-details">
            <h1>GRANT WRITING</h1>
            <p>
              A skilled writer with a clientele that cuts across Nigeria, USA
              and Canada
            </p>
          </div>
        </div>
        <div className="sr2-3">
          <div className="icon">
            <span>
              <TbBrandAngular />
            </span>
          </div>
          <div className="sr-details">
            <h2>BUSINESS NAME AND SLOGANS</h2>
            <p>
              Crafting Bespoke brand names that speak to your target audience.
            </p>
          </div>
        </div>
      </section>
      <section className="training">
        <div className="training-container">
          <h1>THE FREELANCE MASTER CLASS</h1>
          <Rate disabled value={5} />
          <h2>Fast Track your freelance journey</h2>
          <p>
            The freelance master class is an intensive freelance training that
            helps everygrowing lancer seeking a fully endorsed package for
            freelance training and digital endorsed skills that would help you
            leverage the gig economy on fiverr and other prominent platforms
          </p>
          <div className="btn">
            Get Started
            <img src={arrow} alt="" />
          </div>
        </div>
      </section>
      <section className="reviews">
        <div className="reviews-container">
          <div className="rLine1"></div>
          <h1>REVIEWS</h1>
          <div className="rLine2"></div>
        </div>
        <div className="reviews-wrap">
          <div className="reviewC">
            <img src={R1} alt="" />
          </div>
          <div className="reviewC">
            <img src={R2} alt="" />
          </div>
          <div className="reviewC">
            <img src={R3} alt="" />
          </div>
          <div className="reviewC">
            <img src={R4} alt="" />
          </div>
          <div className="reviewC">
            <video controls src={R5} />
          </div>
          <div className="reviewC">
            <video controls src={R6} />
          </div>
        </div>
        <div className="rLine3"></div>
      </section>
      <section className="mentors">
        <h1>Our Mentors</h1>
        <p>
          Our Instructors are top tier when it comes to delivering knowledge
          that would help you reach your personal and career goals
        </p>
        <div className="mentors-row">
          <div className="mentors-col">
            <div className="mentors-avatar">
            <img src={mentors} alt="" />
            </div>
            <h4>Hon_Joshuaa (Digital Nerd)</h4>
            <h5>Founder/Coach</h5>
          </div>
          <div className="mentors-col">
            <div className="mentors-avatar">
              <h1>?</h1>
            </div>
            <h4>Anonymous Coach</h4>
            <h5>6 Figure Freelancer</h5>
          </div>
        </div>
      </section>
      <section className="contact" id="contact">
        <div className="contact-form2">
          <div className="cf-line1"></div>
          <h1>
            Lets make magic together Start by saying <span>HI</span>
          </h1>
          <div className="cf-line2">
            <div className="contact-btn">
              <img src={arrow2} alt="" />
            </div>
          </div>
          <div className="socials-details">
            <div className="logo-name">
              <img src={logo} alt="" />
              <h1>igital Nerd</h1>
            </div>
            <div className="social-icons">
              <span>
                <FaInstagram />
              </span>
              <span>
                <a
                  href="https://twitter.com/Hon_Joshuaa?t=R4nOBq9Ir3My84hh1NC2AQ&s=09"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              </span>
              <span>
                <FaTelegramPlane />
              </span>
              <span>
                <a
                  href="https://www.upwork.com/freelancers/~01d69e10b3d4fc03a6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SiUpwork />
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/in/honour-afangideh-230872246/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#1e2e3e",
                colorTextPlaceholder: "#fff",
              },
              components: {
                Input: {
                  activeBorderColor: "yellow",
                },
              },
            }}
          >
            <Form
              form={form}
              className="form"
              onFinish={handleSubmit}
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Item
                label=""
                name="name"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="NAME"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="phone"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="PHONE NUMBER"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="email"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="EMAIL"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid subject",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="SUBJECT"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Message is empty",
                  },
                ]}
              >
                <TextArea
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="MESSAGE"
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={_message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <hr />
              </Form.Item>
              {loading ? (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "100px",
                    paddingLeft: "100px",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading
                  disabled
                >
                  Send Message
                </Button>
              ) : (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "100px",
                    paddingLeft: "100px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              )}
            </Form>
          </ConfigProvider>
        </div>
      </section>
      <section className="contact2" id="contact">
        <h1>
          Lets make magic together Start by saying <span>HI</span>
        </h1>
        <div className="contact-form">
        <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#1e2e3e",
                colorTextPlaceholder: "#fff",
              },
              components: {
                Input: {
                  activeBorderColor: "yellow",
                },
              },
            }}
          >
            <Form
              form={form}
              className="form"
              onFinish={handleSubmit}
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Item
                label=""
                name="name"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="NAME"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="phone"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="PHONE NUMBER"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="email"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="EMAIL"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Please enter a valid subject",
                  },
                ]}
              >
                <Input
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="SUBJECT"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <hr />
              </Form.Item>
              <Form.Item
                label=""
                name="subject"
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: false,
                    message: "Message is empty",
                  },
                ]}
              >
                <TextArea
                  style={{ color: "#fff", fontFamily: "Poppins" }}
                  bordered={false}
                  placeholder="MESSAGE"
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={_message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <hr />
              </Form.Item>
              {loading ? (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "50px",
                    paddingLeft: "50px",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading
                  disabled
                >
                  Send Message
                </Button>
              ) : (
                <Button
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#f16232",
                    paddingRight: "50px",
                    paddingLeft: "50px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              )}
            </Form>
          </ConfigProvider>
        </div>
        <div className="contact-form2">
          <div className="logo-name2">
            <img src={logo} alt="" />
            <h4>igital Nerd</h4>
          </div>
          <div className="social-icons">
            <span>
              <FaInstagram />
            </span>
            <span>
              <a
                href="https://twitter.com/Hon_Joshuaa?t=R4nOBq9Ir3My84hh1NC2AQ&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </span>
            <span>
              <FaTelegramPlane />
            </span>
            <span>
              <a
                href="https://www.upwork.com/freelancers/~01d69e10b3d4fc03a6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiUpwork />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/in/honour-afangideh-230872246/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </span>
          </div>
        </div>
      </section>
      <footer>
        <hr />
        <div className="footer">
          <h2>2023 all rights reserved</h2>
          <div className="footer-2">
            <h3>Designed by tems</h3>
            <span className="one">
            <FaInstagram/>
            </span>
            <span className="two">
            <FaTwitter/>
            </span>
          </div>
          <div className="footer-socials">
          <span className="one">
            <FaInstagram/>
            </span>
            <span className="two">
            <FaTwitter/>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
